import React from 'react';
import { motion } from 'framer-motion';

const MarqueeMessage = () => {
  return (
    <div className="overflow-hidden whitespace-nowrap bg-gray-200 p-2">
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: '-100%' }}
        transition={{
          repeat: Infinity,
          duration: 20,
          ease: 'linear',
        }}
        className="inline-block text-lg font-semibold text-blue-600"
      >
        <p className="marquee-text">
          🚀 We've recently upgraded our system. If you experience any issues,
          click on <strong>"Have a question or issue?"</strong> and reach out to
          us!
        </p>
      </motion.div>
    </div>
  );
};

export default MarqueeMessage;
