import React, { useEffect, useState } from "react";
import '../../assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import HeaderCourse from "./header-course";
import HeaderEmployee from "./header-employee";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../UserContext";
import HeaderResident from "./header-resident";
import HeaderFilterResident from "./header-filter-resident";

window.globalFilter = window.globalFilter || { value: "0" };
const Header = () => {
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const [isCollapsed, setIsCollapsed] = useState(false);
    const user = useUser();
    const location = useLocation();
    const [showInputBox, setShowInputBox] = useState(false);

    const DisplayHeaderEmployee = location.pathname === "/dashboard" || location.pathname.includes("employee") || location.pathname.includes("ahca-regulations") || location.pathname.includes("task-type") || location.pathname.includes("training-event");
    const DisplayHeaderResident = location.pathname === "/admission-log/" || location.pathname.includes("resident");

    const [searchFilter, setSearchFilter] = useState("0");
    const [searchTerm, setSearchTerm] = useState(""); // Added search term state
    const [showHeaders, setShowHeaders] = useState(false);

    const toggleHeaders = () => {
        setShowHeaders(!showHeaders);
    };

    useEffect(() => {
        if (!token) {
            setError("No token found, redirecting to login...");
            navigate("/login");
            return;
        }

        const root = document.getElementById('root');
        if (root) {
            if (isCollapsed) {
                root.classList.add('nav-collapsed-min');
            } else {
                root.classList.remove('nav-collapsed-min');
            }
        }
    }, [token, navigate, isCollapsed]);

    const toggleSidebar = (e) => {
        e.preventDefault();
        setIsCollapsed((prev) => !prev);
    };

    const handleFilterChange = (newValue) => {
        setSearchFilter(newValue);
        window.globalFilter.value = newValue;
        // Dispatch an event to notify other components
        window.dispatchEvent(new CustomEvent('filterChanged', { detail: newValue }));
    };

    const showInput = () => {
        setShowInputBox(true);
    }


    const renderHeader = (user) => (
        <div className="App">
            <section id="header" className="header-content-container animate-fade-up">
                <div className="header-section">
                    <div className="col-4 d-flex flex-lg-row align-items-center">
                        <div className="menu-button">
                            <a href="#/" onClick={toggleSidebar} className="toggle-min" id="step4">
                                <i className="fa fa-bars"></i>
                            </a>
                        </div>
                        <div className="logo">
                            <span className="pull-left logo-facility-name">
                                {user.user?.facility?.name || "Facility Name"}
                            </span>
                        </div>
                    </div>
                    <div className="col-8">
                        <ul className="nav-left pull-right">
                            {DisplayHeaderResident && (
                                <li className="searchDropdown">
                                    <HeaderFilterResident
                                        searchFilter={searchFilter}
                                        setSearchFilter={handleFilterChange}
                                        searchTerm={searchTerm}
                                        setSearchTerm={setSearchTerm}
                                        isResidentDashboard={false}
                                    />
                                </li>
                            )}
                            {DisplayHeaderEmployee && (
                                <li className="searchDropdown">
                                    <HeaderEmployee />
                                </li>
                            )}

                            {DisplayHeaderResident && (
                                <li className="searchDropdown">
                                    <HeaderResident />
                                </li>
                            )}
                            {showHeaders && (
                                <li className="showFilterResidence">
                                    {DisplayHeaderResident && (
                                    <HeaderFilterResident
                                        searchFilter={searchFilter}
                                        setSearchFilter={handleFilterChange}
                                        searchTerm={searchTerm}
                                        setSearchTerm={setSearchTerm}
                                        isResidentDashboard={false}
                                    />
                                    )}
                                </li>
                                )}
                            {/* {showHeaders && (
                                <li className="showFilterResidence">
                                    {DisplayHeaderResident && <HeaderFilterResident />}
                                </li>
                            )} */}
                            {DisplayHeaderResident && (
                                <li>
                                    <a href="#" className="faq" onClick={toggleHeaders}>
                                        <strong>
                                            <i className="fa-solid fa-filter"></i>
                                        </strong>
                                    </a>
                                </li>
                            )}

                            <li>
                                <a
                                    href="https://alfboss.com/faq/"
                                    className="faq"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <strong>?</strong>
                                </a>
                            </li>
                            <li className="text-normal">
                                <span className="header-name">
                                    {user.user?.user?.first_name || " "}
                                </span>
                            </li>
                            <li className="logout-button">
                                <a href="/logout" className="logout-text" onClick={() => console.log("Logout triggered")}>
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

            </section>
        </div >
    );

    if (error) {
        return renderHeader();
    }

    return renderHeader(user);
};

export default Header;
