import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserInviteAcceptForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  // ✅ Extract Role from Query Params
  const params = new URLSearchParams(location.search);
  const role = decodeURIComponent(params.get('role') || '');


  // ✅ Extract Token from URL
    // ✅ Extract Token from URL (Correctly)
  const getTokenFromURL = () => {
    const urlParts = location.pathname.split('/');
    return urlParts[urlParts.indexOf(id) + 1] || ''; 
  }

  const [form, setForm] = useState({
    first_name: '',
    last_name: '',
    username: '',
    password: '',
    confirm_password: '',
    medical_license_number:'',
    token: getTokenFromURL(), 
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); 


  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start Loader

    let newErrors = {};
    if (!form.first_name) newErrors.first_name = 'First name is required';
    if (!form.last_name) newErrors.last_name = 'Last name is required';
    if (!form.username) newErrors.username = 'Username is required';
    if (!form.password) {
      newErrors.password = 'Password is required';
    } else if (form.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters';
    }
    if (form.password !== form.confirm_password) {
      newErrors.confirm_password = 'Passwords do not match';
    }
    if (role?.toLowerCase() === 'medical examiner' && !form.medical_license_number) {
      newErrors.medical_license_number = 'Medical Examiner Number is required';
    }
    
    

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false);
      toast.error("Please correct the errors in the form!", { position: "top-right" });
      return;
    }

    try {
      const payload = {
        token: form.token, 
        first_name: form.first_name,
        last_name: form.last_name,
        username: form.username,
        password: form.password,
        confirm_password: form.confirm_password,
        medical_license_number:form.medical_license_number
      };
      

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user-invites/${id}/accept/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data.non_field_errors && data.non_field_errors.includes("This invitation has already been accepted. You may now login.")) {
        toast.success("Invitation already accepted! Redirecting to login...", { position: "top-center" });
        setTimeout(() => navigate('/login', { replace: true }), 2000);
        return;
      }
      if (!response.ok) {
        
        if (data && typeof data === "object") {
          setErrors(data);
          Object.values(data).forEach((msg) => toast.error(msg[0], { position: "top-center" }));
        } else {
          toast.error("Something went wrong!", { position: "top-center" });
        }
        setLoading(false);
        return;
      }
     

      toast.success("Account created successfully!", { position: "top-center" });
      const tokenValue = data?.token;
      navigate(`/login/?utoken=${tokenValue}`);

    } catch (err) {
          toast.error("Something went wrong, Please try again later");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='invite-container'>
    <section className="invite-panel">
      <ToastContainer className="toast-container-right"/> {/* ✅ Toaster for Messages */}
      <div className="invite-panel-heading">
        <h1>Create your ALF Boss User</h1>
      </div>
      <div className="invite-panel-body">
        <form onSubmit={handleSubmit}>
          <div className="invite-row row">
            <div className="invite-col-6 col-xs-6">
              <div className="invite-form-group">
                <label>First Name</label>
                <input type="text" className="form-control" name="first_name"
                  value={form.first_name} onChange={handleChange} />
                {errors.first_name && <div className="invite-form-error">{errors.first_name}</div>}
              </div>
            </div>
            <div className="invite-col-6 col-xs-6">
              <div className="invite-form-group">
                <label>Last Name</label>
                <input type="text" className="form-control" name="last_name"
                  value={form.last_name} onChange={handleChange} />
                {errors.last_name && <div className="invite-form-error">{errors.last_name}</div>}
              </div>
            </div>
          </div>

          <div className="invite-form-group">
            <label>Username</label>
            <input type="text" className="form-control" name="username"
              value={form.username} onChange={handleChange} />
            {errors.username && <div className="invite-form-error">{errors.username}</div>}
          </div>

          <div className="invite-form-group">
            <label>Password</label>
            <input type="password" className="form-control" name="password"
              value={form.password} onChange={handleChange} />
            {errors.password && <div className="invite-form-error">{errors.password}</div>}
          </div>

          <div className="invite-form-group">
            <label>Confirm Password</label>
            <input type="password" className="form-control" name="confirm_password"
              value={form.confirm_password} onChange={handleChange} />
            {errors.confirm_password && <div className="invite-form-error">{errors.confirm_password}</div>}
          </div>

          {role.toLowerCase() === 'medical examiner' && (
          <div className="invite-form-group">
            <label>Medical Examiner Number</label>
            <input
              type="text"
              className="form-control"
              name="medical_license_number"
              value={form.medical_license_number || ''}
              onChange={handleChange}
            />
            {errors.medical_license_number && <div className="invite-form-error">{errors.medical_license_number}</div>}
          </div>
        )}

          <div className="invite-form-group">
            <button type="submit" className="btn btn-lg btn-block btn-success invite-btn" disabled={loading}>
              {loading ? "Processing..." : "Create User"}
            </button>
          </div>
        </form>
      </div>
    </section>
    </div>
  );
};

export default UserInviteAcceptForm;
