
import React, { useState, useEffect, useRef } from 'react';
import '../assets/css/style.css'; // Include your CSS file
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from './partials/footer';

const SignUp = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        phone: '',
        facility: '',
        services: [],
        terms: true,
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const apiCalledRef = useRef();

    useEffect(() => {

        if (!apiCalledRef.current) {
            apiCalledRef.current = true;
            fetchQuestions();
        }

    });

    const fetchQuestions = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/facility_questions/`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                toast.error("Something went wrong, Please try again later.");
            }

            const data = await response.json();
            setQuestions(data.map(q => ({ ...q, selected: false }))); // Initialize with selected as false
        } catch (err) {
            toast.error("Something went wrong, Please try again later", err);
            navigate('/error');
        }
    };

    const handleChange = (e, serviceId) => {
        const { type, checked, name, value } = e.target;

        if (type === 'checkbox' && serviceId) {
            setFormData((prevData) => ({
                ...prevData,
                services: checked
                    ? [...prevData.services, serviceId] // Add the service ID
                    : prevData.services.filter((id) => id !== serviceId), // Remove the service ID
            }));
        } else if (type === 'checkbox') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: checked,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };


    const toggleQuestion = (questionId) => {
        setFormData((prevData) => {
            const isSelected = prevData.services.includes(questionId);
            return {
                ...prevData,
                services: isSelected
                    ? prevData.services.filter((id) => id !== questionId) // Remove if already selected
                    : [...prevData.services, questionId], // Add if not selected
            };
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }
        setLoading(true);
        const signupData = {
            user: {
                // "username": formData.username.toLowerCase(), // Convert to lowercase only when sending to API
                "username": formData.username,
                "password": formData.password,
                "conf_password": formData.confirmPassword,
                "first_name": formData.firstName,
                "last_name": formData.lastName,
                "email": formData.emailAddress,
            },
            facility: {
                questions: formData.services,
                name: formData.facility,
                fcc_signup: false,
                contact_phone: formData.phone,
            },
            terms: {
                "agree": formData.terms
            },


        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/signup/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(signupData),
            });

            if (!response.ok) {
                const errorData = await response.json();

                if (Array.isArray(errorData.facility?.contact_phone) && errorData.facility.contact_phone[0] === "Number is not a valid US number!") {
                    toast.error('Number is not a valid US number!');
                } else if (Array.isArray(errorData.user?.username) && errorData.user.username[0] === "A user with that username already exists.") {
                    toast.error("A user with that username already exists.");
                }

                else {
                    toast.error(`Signup failed: ${errorData.message || "Please try again later."}`);
                }

                setLoading(false);
                return;
            }

            const result = await response.json();
            const loginData = {
                // username: formData.username.toLowerCase(),
                username: formData.username,
                password: formData.password,
                client_id: process.env.REACT_APP_CLIENT_ID, // Add your client_id here
            };

            const loginResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(loginData),
            });

            if (!loginResponse.ok) {
                const loginErrorData = await loginResponse.json();
                alert(`Login failed: ${loginErrorData.message}`);
                return;
            }

            const loginResult = await loginResponse.json();
            if (loginResult.token) {
                localStorage.setItem("token", loginResult.token);
            }

            navigate("/dashboard");
            toast.success("Created account successfully. Logged in!")

        } catch (error) {
            toast.error('An error occurred during signup. Please try again later.');
        }
        setLoading(false);
    };

    return (
        <div className="App">
            {loading && (
                <div className="overlay">
                    <div className="loader-wrapper">
                        <Loader height={120} width={120} />
                    </div>
                </div>
            )}

            <ToastContainer />
            <section id="content" className={`content-container ${loading ? 'blur' : ''}`}>
                <div className="page-signup">
                    <div className="signup-body">
                        <div className="row-div">
                            <div className="col signup-logo">
                                <img
                                    src="/images/alf-logo.png"
                                    height="236"
                                    width="200"
                                    alt="Logo"
                                />
                            </div>
                            <div className='message-box'>
                                <div className="notice-logo">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                </div>
                                <div className="notice-text">
                                    <p className='textmessage'>
                                        This page is for <strong>Facility Admin Signup</strong> only.
                                        If you are an employee, please do not sign up here. You will receive an invitation email once your admin adds you to the employee list. If you haven’t received it yet, please reach out to your facility administrator.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 signup-form">
                                <div className="form-div">
                                    <form className="form-horizontal" onSubmit={handleSubmit}>
                                        <fieldset>
                                            <div className="row">
                                                <div className="col-md-6 col-xs-12">
                                                    <h2 className="text-center">Account Info</h2>
                                                    {[
                                                        { label: 'Create Username', name: 'username', type: 'text' },
                                                        { label: 'Create Password', name: 'password', type: 'password' },
                                                        { label: 'Confirm Password', name: 'confirmPassword', type: 'password' },
                                                        { label: 'First Name', name: 'firstName', type: 'text' },
                                                        { label: 'Last Name', name: 'lastName', type: 'text' },
                                                        { label: 'Email Address', name: 'emailAddress', type: 'email' },
                                                        { label: 'Phone Number', name: 'phone', type: 'text' },
                                                        { label: 'Facility Name', name: 'facility', type: 'text' },
                                                    ].map((field, index) => (
                                                        <div className="form-section" key={index}>
                                                            <div className="col-sm-2 signup-page">
                                                                <label>{field.label}</label>
                                                            </div>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type={field.type}
                                                                    className="form-control input-lg"
                                                                    required
                                                                    id={field.name}
                                                                    name={field.name}
                                                                    value={formData[field.name]}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="col-md-6 col-xs-12">
                                                    <section className="panel panel-default">
                                                        <div className="panel-headings">SERVICES</div>
                                                        <div className="panel-body">
                                                            <section className="panel panel-default">
                                                                <div className="panel-body">
                                                                    {["license", "non-license"].map((type) => (
                                                                        <section key={type} className="panel panel-default" style={{ border: '1px solid #c2bfbf', borderRadius: '5px' }}>
                                                                            <div className="panel-body">
                                                                                <table className="table">
                                                                                    <tbody>
                                                                                        {questions
                                                                                            .filter((q) => (type === "license" ? q.is_license === true : q.is_license === false)) // Strict filtering logic
                                                                                            .map((question) => (
                                                                                                <tr key={question.id} className='service-row'>
                                                                                                    <td width="100">
                                                                                                        <label className="switch">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                checked={formData.services.includes(question.id)} // Ensure the checkbox reflects the selected state
                                                                                                                onChange={() => toggleQuestion(question.id)} // Toggle the question selection
                                                                                                            />
                                                                                                            <i></i>
                                                                                                        </label>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span>{question.question}</span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </section>
                                                                    ))}

                                                                </div>
                                                            </section>
                                                        </div>
                                                    </section>
                                                    <div>
                                                        <p>
                                                            <input
                                                                type="checkbox"
                                                                className='check-signup'
                                                                name="agreeToTerms"
                                                                checked={formData.agreeToTerms}
                                                                onChange={handleChange}
                                                                required
                                                            />
                                                            I agree to the
                                                            <a href="#/terms-and-conditions" target="_blank">
                                                                Terms and Conditions and Privacy Policy
                                                            </a>.
                                                        </p>
                                                    </div>
                                                    <button className="btn btn-info btn-block btn-lg" type="submit">
                                                        Create free facility account

                                                    </button>

                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                    <Footer />
                </div>
            </section>
        </div>
    );
};

export default SignUp;

