import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate, Link } from "react-router-dom";

const HeaderEmployee = () => {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const apiCalledRef = useRef(false);
  const cacheBuster = Date.now();

  const fetchEmployeeData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/employees/?cacheBuster=${cacheBuster}&full_name=`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();

        if (response.status === 401) {
          setError("Unauthorized. Redirecting to login...");
          localStorage.removeItem("token");
          navigate("/login");
        } else if (
          response.status === 403 &&
          errorData.detail === "You do not have permission to perform this action."
        ) {
          setEmployees([]); // Keep input box but show "No results found"
          setFilteredEmployees([]);
        } else {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
      } else {
        const employeeData = await response.json();
        setEmployees(employeeData);
        setFilteredEmployees(employeeData);
      }
    } catch (err) {
      setError(err.message);
    }
  };


  useEffect(() => {
    if (!token) {
      setError("No token found, redirecting to login...");
      navigate("/login");
      return;
    }

    if (!apiCalledRef.current) {
      apiCalledRef.current = true;
      fetchEmployeeData();
    }
  }, [token, navigate]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.trim() === "") {
      setFilteredEmployees(employees);
    } else {
      setFilteredEmployees(
        employees.filter((employee) =>
          employee.full_name.toLowerCase().includes(query.toLowerCase())
        )
      );
    }
  };


  const handleSelectEmployee = (employee) => {
    setSearchQuery(`${employee.full_name}`);
    setIsDropdownVisible(false);

    setTimeout(() => {
      navigate(`/employeeview/${employee.id}`);
    }, 200);
  };

  const renderHeaderEmployeeSearch = () => (
    <div className="header-employee-search">
      <div className="employee-search-data">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Employee Search"
            value={searchQuery}
            onChange={handleSearch}
            onFocus={() => setIsDropdownVisible(true)}
            className="w-full p-2 border rounded mb-2"
            aria-label="Search Employees"
          />
          <i
            onClick={() => setIsDropdownVisible((prev) => !prev)}
            className={`fas ${isDropdownVisible ? "fa-chevron-up" : "fa-chevron-down"
              } search-icon`}
            role="button"
            aria-expanded={isDropdownVisible}
            aria-label="Toggle Dropdown"
          ></i>
        </div>
        {isDropdownVisible && (
          <div className="employee-dropdown w-full border rounded">
            {filteredEmployees.length > 0 ? (
              <div className="option-div">
                {filteredEmployees.map((employee) => (
                  <div className="option-values" key={employee.id}
                    onClick={() => handleSelectEmployee(employee)}
                  >
                    {employee?.full_name}
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-results">No results found</div>
            )}
          </div>
        )}
      </div>
    </div>
  );

  return renderHeaderEmployeeSearch();
};

export default HeaderEmployee;
