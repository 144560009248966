import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("token");
 const location=useLocation();
  // Check if token exists
  if (location.pathname === "/signup") {
    return children;
  }
  if (!token) {
    return <Navigate to="/login" />;
  }
  

  return children;
};

export default ProtectedRoute;
