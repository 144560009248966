import React from "react";
import { useNavigate } from "react-router-dom";
import Master from "../partials/master";

const CourseError = () => {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate("/dashboard"); 
    };

    return (
        <Master>
            <div style={styles.container}>
                <h2 style={styles.heading}>⚠️ Something Went Wrong!</h2>
                <h4 style={styles.heading}>Please try again later.</h4>
                <button style={styles.button} onClick={handleLogin}>Go to Dashboard</button>
            </div>
        </Master>
    );
};

// 🔹 Inline CSS Styles
const styles = {
    container: {
        textAlign: "center",
        padding: "50px",
        maxWidth: "500px",
        margin: "100px auto",
        backgroundColor: "#fff3cd",
        border: "1px solid #ffeeba",
        borderRadius: "10px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
    },
    heading: {
        color: "#856404",
        fontSize: "24px",
        marginBottom: "10px",
    },
    message: {
        color: "#856404",
        fontSize: "16px",
        marginBottom: "20px",
    },
    button: {
        padding: "10px 20px",
        fontSize: "16px",
        backgroundColor: "#ffc107",
        color: "#856404",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        transition: "background 0.3s ease",
    }
};

export default CourseError;
