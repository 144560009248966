import React, { useEffect, useState } from "react";
import '../../assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../UserContext";

window.globalFilter = window.globalFilter || { value: "0" };
const Footer = () => {
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const user = useUser();
    const location = useLocation();


    useEffect(() => {
        const existingScript = document.querySelector('script[src="https://js.chatlio.com/widget.js"]');

        if (!existingScript) {
            const script = document.createElement("script");
            script.src = "https://js.chatlio.com/widget.js";
            script.async = true;
            script.onload = () => console.log("Chatlio script loaded successfully.");
            script.onerror = () => console.error("Failed to load Chatlio script.");
            document.body.appendChild(script);
        } else {
            // console.log("Chatlio script already loaded.");
        }

        // Check if the widget already exists
        if (!document.querySelector("chatlio-widget")) {
            const chatlioWidget = document.createElement("chatlio-widget");
            chatlioWidget.setAttribute("widgetid", "91d12032-72f1-4f01-72ff-c5ef41d11870");
            document.body.appendChild(chatlioWidget);
        } else {
            // console.log("Chatlio widget already exists.");
        }

        // Cleanup (optional, depending on your needs)
        return () => {
            const widget = document.querySelector("chatlio-widget");
            if (widget) document.body.removeChild(widget);
        };
    }, []);



    const renderFooter = (user) => (
        <div className="App">
            <section id="footers" className="footer-content-container animate-fade-up">
                <div className="footer-section row">
                    <p class="footer-heart">
                        Made with <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="red"
                        >
                            <path d="M12 21.35L10.55 20.03C5.4 15.36 2 12.28 2 8.5C2 5.42 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.09C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.42 22 8.5C22 12.28 18.6 15.36 13.45 20.04L12 21.35Z" />
                        </svg> by <a href="https://evergreenbrain.com/" className="site-link" target="_blank">Evergreen Brain</a>
                    </p>
                </div>

            </section>
        </div >
    );

    return renderFooter(user);
};

export default Footer;
