import React from 'react';
import { ColorRing } from 'react-loader-spinner';
import { RotatingLines, Bars } from 'react-loader-spinner';

const Loader = ({ visible = true, height = 200, width = 200, wrapperStyle = {}, wrapperClass = "" }) => {
    return (
        <div className={wrapperClass} style={wrapperStyle}>
            <Bars
                height="80"
                width="80"
                color="#047D62"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    );
};

export default Loader;
