import React, { useEffect, useState, useRef } from 'react';
import Master from '../partials/master';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from "../UserContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Success = () => {
    const token = localStorage.getItem("token");
    const facilityId = localStorage.getItem("facility_id");
    const navigate = useNavigate();
    const location = useLocation();
    const [subscriptionId, setSubscriptionId] = useState(null);
    const [billingInterval, setBillingInterval] = useState(null);
    const [planName, setPlanName] = useState(null);
    const [priceAmount, setPriceAmount] = useState(null);
    const [nextBillingDate, setNextBillingDate] = useState(null);
    const apiCalledRef = useRef(false);
    const facility = useUser();
    const facilityData = facility?.user?.facility;
    const finalFacilityId = facilityData?.id || facilityId;

    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    };

    // ✅ URL से Try करो
    let updateTrialParam = getQueryParam("updateTrial") === "true";

    // ✅ अगर URL से नहीं मिला, तो Session Storage से Try करो
    if (!updateTrialParam) {
        updateTrialParam = sessionStorage.getItem("updateTrial") === "true";
    }



    const fetchSubscriptionDetails = async (sessionId, facilityId) => {
        if (!facilityId) {
            // console.error("No facility ID available");
            return;
        }
        if (!sessionId) {
            // console.error("No session ID available");
            return;
        }

        try {
            let url = `${process.env.REACT_APP_API_BASE_URL}/stripe/checkout-session/${sessionId}/?facility_id=${facilityId}`;
            if (updateTrialParam) {
                url += "&updateTrial=true";
            }

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || "Failed to fetch subscription details");
            }

            const data = await response.json();
            setSubscriptionId(data.subscription_id);
            setPlanName(data.plan_name || "N/A");
            setBillingInterval(data.billing_interval || "N/A");
            setPriceAmount(data.price_amount || 0);
            setNextBillingDate(data.next_billing_date || "N/A");

        } catch (error) {
            toast.error("Something went wrong, Please try again later", error);
            navigate('/error');
        }
    };

    useEffect(() => {
        if (!token) {
            navigate("/login");
            return;
        }

        const query = new URLSearchParams(location.search);
        let sessionId = query.get('session_id');


        try {
            if (sessionId) {
                sessionId = decodeURIComponent(sessionId);

                if (!/^[a-zA-Z0-9-_]+$/.test(sessionId)) {
                    throw new Error("❌ session_id contains invalid characters.");
                }
            }
        } catch (error) {
            toast.error("Something went wrong, Please try again later", error);
            navigate('/error');
        }

        if (!sessionId) {
            // console.warn("⚠️ No session ID found in URL.");
            return;
        }

        if (sessionId && finalFacilityId && !apiCalledRef.current) {
            apiCalledRef.current = true;
            fetchSubscriptionDetails(sessionId, finalFacilityId);
        }

    }, [token, navigate, location, finalFacilityId]);

    return (
        <Master>
            <ToastContainer />
            <div className="success-content">
                <div className="success-heading">
                    <h2>🎉 Thank you for your payment!</h2>
                </div>
                <div className='success-body'>
                    {/* {subscriptionId && <p>✅ Your Subscription ID: <strong>{subscriptionId}</strong></p>} */}
                    {planName && <p>📜Your Plan: <strong>{planName}</strong></p>}
                    {priceAmount && <p>💲 Amount Paid: <strong>${priceAmount}</strong></p>}
                    {/* {nextBillingDate && <p>📅 Next Billing Date: <strong>{nextBillingDate}</strong></p>} */}

                </div>
                {/* <div className='back-button col-md-7'> */}
                <a href='/facility-subscription'><button className='btn btn-primary tutorial'>Back</button></a>
                {/* </div> */}
            </div>
        </Master>
    );
};

export default Success;
