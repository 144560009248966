import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate } from "react-router-dom";

const HeaderResident = () => {
  const [residents, setResidents] = useState([]);
  const [filteredResidents, setFilteredResidents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const apiCalledRef = useRef(false);
  const cacheBuster = Date.now();

  const fetchResidentData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/residents/?cacheBuster=${cacheBuster}&full_name=&is_active=True&order_by=last_name`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (!response.ok) {
        if (response.status === 403) {
          const errorResult = await response.json();
          if (errorResult.detail === "no_resident_subscription") {
            navigate("/subscription"); // Redirect user to subscription page
            return;
          }

        }
        if (response.status === 401) {
          setError("Unauthorized. Redirecting to login...");
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
      } else {
        const residentData = await response.json();
        setResidents(residentData);
        setFilteredResidents(residentData);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    if (!token) {
      setError("No token found, redirecting to login...");
      navigate("/login");
      return;
    }

    if (!apiCalledRef.current) {
      apiCalledRef.current = true;
      fetchResidentData();
    }
  }, [token, navigate]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.trim() === "") {
      setFilteredResidents(residents);
    } else {
      setFilteredResidents(
        residents.filter((resident) =>
          resident.full_name?.toLowerCase().includes(query.toLowerCase())
        )
      );
    }
  };

  const handleSelectResident = (resident) => {
    setSearchQuery(`${resident.first_name} ${resident.last_name}`); 
    setIsDropdownVisible(false); 

    setTimeout(() => {
      navigate(`/residents/${resident.id}/edit`); 
    }, 200); 
  };

  const renderHeaderEmployeeSearch = () => (
    <div className="header-employee-search">
      <div className="employee-search-data">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Resident Search"
            value={searchQuery}
            onChange={handleSearch}
            onFocus={() => setIsDropdownVisible(true)}
            className="w-full p-2 border rounded mb-2"
            aria-label="Search Resident"
          />
          <i
            onClick={() => setIsDropdownVisible((prev) => !prev)}
            className={`fas ${
              isDropdownVisible ? "fa-chevron-up" : "fa-chevron-down"
            } search-icon`}
            role="button"
            aria-expanded={isDropdownVisible}
            aria-label="Toggle Dropdown"
          ></i>
        </div>
        {isDropdownVisible && (
          <div className="resident-dropdown w-full border rounded">
            {filteredResidents.length > 0 ? (
              <div className="option-div">
                {filteredResidents.map((resident) => (
                  <div
                    className="option-values"
                    key={resident.id}
                    onClick={() => handleSelectResident(resident)}
                    style={{ cursor: "pointer" }}
                  >
                    <span>{resident.first_name} {resident.last_name}</span>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-results">No results found</div>
            )}
          </div>
        )}
      </div>
    </div>
  );

  return renderHeaderEmployeeSearch();
};

export default HeaderResident;
