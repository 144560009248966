import React, { Suspense, lazy } from "react";
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import 'bootstrap/dist/css/bootstrap.min.css';
import SignUp from "./components/SignUp";
import Logout from "./components/Logout";
import { UserProvider } from './components/UserContext';
import Success from "./components/subscription/success";
import ErrorBoundary from "./components/ErrorBoundary";
import * as Sentry from "@sentry/react";
import PasswordReset from "./components/password-reset";
import CourseError from "./components/Courses/ErrorPage";
import UserInviteAcceptForm from "./components/facility-details/userinvitesaccept";
import PasswordResetConfirm from "./components/password-reset-confirm";
import DefaultDashboard from "./components/DefaultDashboard";

// Initialize Sentry
// Sentry.init({
//   dsn: "https://7d3b8bad74440a3acadedfdec5c66bde@o4508811146362880.ingest.us.sentry.io/4509020815491072",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   tracesSampleRate: 1.0,
//   tracePropagationTargets: ["login.alfboss.com", /^https:\/\/admin2\.alfcoretrainingflorida\.com\/api/],
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

 // Lazy loaded components
const Login = lazy(() => import("./components/Login"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const ResidentList = lazy(() => import("./components/resident/ResidentList"));
const ResidentDashboard = lazy(() => import("./components/resident/ResidentDashboard"));
const ResidentView = lazy(() => import("./components/resident/ResidentView"));
const AddResident = lazy(() => import("./components/resident/add-resident"));
const EditResident = lazy(() => import("./components/resident/edit-resident"));
const EditManagerAccess = lazy(() => import('./components/facility-details/EditManagerAccess'));
const AddEmployee = lazy(() => import('./components/employee/AddEmployee'));
const EditEmployee = lazy(() => import('./components/employee/EditEmployee'));
const EmployeeList = lazy(() => import('./components/employee/Employeelist'));
const EmployeeView = lazy(() => import('./components/employee/Employeeview'));
const EmployeeDescription = lazy(() => import('./components/employee/EmployeeDescription'));
const TrainingEventList = lazy(() => import('./components/training-events/scheduled-training'));
const TrainingEvent = lazy(() => import('./components/training-events/training-event'));
const AddTrainingEvent = lazy(() => import('./components/training-events/training-event-add'));
const EditTrainingEvent = lazy(() => import('./components/training-events/training-event-edit'));
const Training = lazy(() => import('./components/Courses/Take_Online_Courses'));
const CourseMcq = lazy(() => import('./components/Courses/CourseItems/course-mcq-item'));
const CourseBoolean = lazy(() => import('./components/Courses/CourseItems/course-boolean-item'));
const CourseText = lazy(() => import('./components/Courses/CourseItems/course-text-item'));
const CourseImage = lazy(() => import('./components/Courses/CourseItems/course-image-item'));
const CourseVideo = lazy(() => import('./components/Courses/CourseItems/course-video-item'));
const CourseDetail = lazy(() => import('./components/Courses/course-details'));
const CourseSignature = lazy(() => import('./components/Courses/course-signature'));
const CourseSummary = lazy(() => import('./components/Courses/course-summary'));
const CustomTaskTypes = lazy(() => import('./components/CustomTraining/Custom_Task_Types'));
const AddCustomTask = lazy(() => import('./components/CustomTraining/AddCustomTraining'));

const FacilityDetails = lazy(() => import('./components/facility-details/FacilityDetails'));
const Admission = lazy(() => import('./components/resident/admission-log/Admission'));
const SubscriptionStaff = lazy(() => import('./components/subscription/subscription-staff'));
const AhcaRegulations = lazy(() => import('./components/account/ahca-regulations'))
const TutorialVideo = lazy(() => import('./components/account/tutorials'));
const AddTaskType = lazy(() => import('./components/account/add-task-type'));
const TutorialVideos = lazy(() => import('./components/account/tutorial-video'));
const UpgradeSubscription = lazy(() => import('./components/subscription/upgrade-subscription'));

const TaskTypeView = lazy(() => import('./components/account/task-type-view'));
const EditTaskType = lazy(() => import('./components/account/edit-task-type'));
const Subscription = lazy(() => import('./components/subscription/subscription-modal'));
const TrialSubscriptionStaff = lazy(() => import('./components/subscription/trial-subscription-staff'));
const FacilityExaminerInvites = lazy(() => import('./components/facility-details/FacilityExaminerInvites'));
const AnnouncementSubscription = lazy(() => import('./components/subscription/announcement-subscription-training'));
const Sponsorship = lazy(() => import('./components/subscription/sponsorship'));


const App = () => {
 
  return (
    <Sentry.ErrorBoundary fallback={<p>Something went wrong!</p>}>
      <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <ErrorBoundary>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/password/reset" element={<PasswordReset />} />

        {/* {/ Protected Route for Dashboard Page/} */}
        <Route
          path="/home"
          element={<ProtectedRoute>
            <UserProvider>
              <DefaultDashboard />
            </UserProvider>
          </ProtectedRoute>}
        />

        {/* {/ Protected Route for Dashboard /} */}
        <Route
          path="/dashboard"
          element={<ProtectedRoute>
            <UserProvider>
              <Dashboard />
            </UserProvider>
          </ProtectedRoute>}
        />
        {/* {resident dashboard} */}

        <Route
          path="/resident-dashboard"
          element={<ProtectedRoute>
            <UserProvider>
              <ResidentDashboard />
            </UserProvider>
          </ProtectedRoute>}
        />

<Route
  path="/residents/:id/edit-1823"
  element={
    <ProtectedRoute>
      <UserProvider>
        <EditResident />
      </UserProvider>
    </ProtectedRoute>
  }
/>

<Route
  path="/residentsview/:id/"
  element={
    <ProtectedRoute>
      <UserProvider>
        <ResidentView />
      </UserProvider>
    </ProtectedRoute>
  }
/>

<Route
  path="/residents/:id/archives"
  element={
    <ProtectedRoute>
      <UserProvider>
        <EditResident />
      </UserProvider>
    </ProtectedRoute>
  }
/>
        {/* Resident List Route */}
        <Route
          path="/residentlist"
          element={
            <ProtectedRoute>
              <UserProvider>
                <ResidentList />
              </UserProvider>
            </ProtectedRoute>
          }
        />

        {/* Add Resident Route  */}
        <Route
          path="/add-resident"
          element={
            <ProtectedRoute>
              <UserProvider>
                <AddResident />
              </UserProvider>
            </ProtectedRoute>
          }
        />
 
        <Route
          path="/admission-log"
          element={
            <ProtectedRoute>
              <UserProvider>
                <Admission />
              </UserProvider>
            </ProtectedRoute>
          }
        />
        {/* Employee List Route */}
        <Route
          path="/employeelist"
          element={
            <ProtectedRoute>
              <UserProvider>
                <EmployeeList />
              </UserProvider>
            </ProtectedRoute>
          }
        />
        {/* Employee View list route */}
        <Route
          path="/employeeview/:id"
          element={
            <ProtectedRoute>
              <UserProvider>
                <EmployeeView />
              </UserProvider>
            </ProtectedRoute>
          }
        />

        {/* Employee Add list route */}
        <Route
          path="/add-employee"
          element={
            <ProtectedRoute>
              <UserProvider>
                <AddEmployee />
              </UserProvider>
            </ProtectedRoute>
          }
        />
        
      

{/* Employee Description  route */}
<Route
  path="/employeeview/:id/description"
  element={
    <ProtectedRoute>
      <UserProvider>
        <EmployeeDescription />
      </UserProvider>
    </ProtectedRoute>
  }
/>
  {/* Employee Edit list route */}
  <Route
          path="/employeeview/:id/edit"
          element={
            <ProtectedRoute>
              <UserProvider>
                <EditEmployee />
              </UserProvider>
            </ProtectedRoute>
          }
        />

{/* Custom Task Types Route */}
<Route
  path="/tasktypes"
  element={
    <ProtectedRoute>
      <UserProvider>
        <CustomTaskTypes />
      </UserProvider>
    </ProtectedRoute>
  }
/>
{/* Add Custom Task Types Route */}
<Route
  path="/add-customTrainings"
  element={
    <ProtectedRoute>
      <UserProvider>
        <AddCustomTask/>
      </UserProvider>
    </ProtectedRoute>
  }
/>


        {/* Add Training Event route */}
        <Route
          path="/training-event/add/:id"
          element={
            <ProtectedRoute>
              <UserProvider>
                <AddTrainingEvent />
              </UserProvider>
            </ProtectedRoute>
          }
        />

        {/* Add Training Event route */}
        {/* <Route
          path="/training-event/:id"
          element={
            <ProtectedRoute>
              <UserProvider>
                <TrainingEvent />
              </UserProvider>
            </ProtectedRoute>
          }
        /> */}


        <Route path="/dashboard" element={<ProtectedRoute><UserProvider><Dashboard /></UserProvider></ProtectedRoute>} />
        <Route path="/resident-dashboard" element={<ProtectedRoute><UserProvider><ResidentDashboard /></UserProvider></ProtectedRoute>} />


        <Route path="/residents/:id/edit-1823" element={<ProtectedRoute><UserProvider><EditResident /></UserProvider></ProtectedRoute>} />
        <Route path="/residents/:id/archives" element={<ProtectedRoute><UserProvider><EditResident /></UserProvider></ProtectedRoute>} />
        <Route path="/residents/:id/edit" element={<ProtectedRoute><UserProvider><EditResident /></UserProvider></ProtectedRoute>} />
        <Route path="/residents/:id/examiners" element={<ProtectedRoute><UserProvider><EditResident /></UserProvider></ProtectedRoute>} />

        <Route path="/residentlist" element={<ProtectedRoute><UserProvider><ResidentList /></UserProvider></ProtectedRoute>} />
        <Route path="/add-resident" element={<ProtectedRoute><UserProvider><AddResident /></UserProvider></ProtectedRoute>} />
        <Route path="/admission-log" element={<ProtectedRoute><UserProvider><Admission /></UserProvider></ProtectedRoute>} />

        <Route path="/employeelist" element={<ProtectedRoute><UserProvider><EmployeeList /></UserProvider></ProtectedRoute>} />
        <Route path="/employeeview/:id" element={<ProtectedRoute><UserProvider><EmployeeView /></UserProvider></ProtectedRoute>} />
        <Route path="/add-employee" element={<ProtectedRoute><UserProvider><AddEmployee /></UserProvider></ProtectedRoute>} />
        <Route path="/employeeview/:id/description" element={<ProtectedRoute><UserProvider><EmployeeDescription /></UserProvider></ProtectedRoute>} />

        <Route path="/tasktypes" element={<ProtectedRoute><UserProvider><CustomTaskTypes /></UserProvider></ProtectedRoute>} />
        <Route path="/add-customTrainings" element={<ProtectedRoute><UserProvider><AddCustomTask /></UserProvider></ProtectedRoute>} />

        <Route path="/training-event/add/:id?" element={<ProtectedRoute><UserProvider><AddTrainingEvent /></UserProvider></ProtectedRoute>} />
        <Route path="/training-event/:id" element={<ProtectedRoute><UserProvider><TrainingEvent /></UserProvider></ProtectedRoute>} />
        <Route path="/training-events" element={<ProtectedRoute><UserProvider><TrainingEventList /></UserProvider></ProtectedRoute>} />
        <Route path="/training-event/:id/edit" element={<ProtectedRoute><UserProvider><EditTrainingEvent /></UserProvider></ProtectedRoute>} />

        <Route path="/facility-details" element={<ProtectedRoute><UserProvider><FacilityDetails /></UserProvider></ProtectedRoute>} />
        <Route path="/subscription-staff" element={<ProtectedRoute><UserProvider><SubscriptionStaff /></UserProvider></ProtectedRoute>} />
        <Route path="/subscription-staff?updateTrial=true" element={<ProtectedRoute><UserProvider><SubscriptionStaff /></UserProvider></ProtectedRoute>} />
        <Route path="/subscription" element={<ProtectedRoute><UserProvider><Subscription /></UserProvider></ProtectedRoute>} />
        <Route path="/trial-subscription-staff" element={<ProtectedRoute><UserProvider><TrialSubscriptionStaff /></UserProvider></ProtectedRoute>} />

        <Route path="/facility-employees" element={<ProtectedRoute><UserProvider><FacilityDetails /></UserProvider></ProtectedRoute>} />
        <Route path="/facility-manager" element={<ProtectedRoute><UserProvider><FacilityDetails /></UserProvider></ProtectedRoute>} />
        <Route path="/facility-examiner" element={<ProtectedRoute><UserProvider><FacilityDetails /></UserProvider></ProtectedRoute>} />
        <Route path="/facility-subscription" element={<ProtectedRoute><UserProvider><FacilityDetails /></UserProvider></ProtectedRoute>} />
        <Route path="/subscription" element={<ProtectedRoute><UserProvider><Subscription /></UserProvider></ProtectedRoute>} />
        <Route path="/sponsorship" element={<ProtectedRoute><UserProvider><Sponsorship /></UserProvider></ProtectedRoute>} />
        <Route path="/announcement-subscription" element={<ProtectedRoute><UserProvider><AnnouncementSubscription /></UserProvider></ProtectedRoute>} />
        <Route path="/success" element={<ProtectedRoute><UserProvider><Success /></UserProvider></ProtectedRoute>} />
        <Route path="/upgrade-subscription" element={<ProtectedRoute><UserProvider><UpgradeSubscription /></UserProvider></ProtectedRoute>} />

        <Route path="/courses" element={<ProtectedRoute><UserProvider><Training/></UserProvider></ProtectedRoute>} />


        <Route path="/course-details/:id" element={<ProtectedRoute><UserProvider><CourseDetail /></UserProvider></ProtectedRoute>} />
        <Route path="/course-details/:id/item/text/:itemId" element={<ProtectedRoute><UserProvider><CourseText /></UserProvider></ProtectedRoute>} />
        <Route path="/course-details/:id/item/boolean/:itemId" element={<ProtectedRoute><UserProvider><CourseBoolean /></UserProvider></ProtectedRoute>} />
        <Route path="/course-details/:id/item/mcq/:itemId" element={<ProtectedRoute><UserProvider><CourseMcq /></UserProvider></ProtectedRoute>} />

        <Route path="/course-details/:id/item/image/:itemId" element={<ProtectedRoute><UserProvider><CourseImage /></UserProvider></ProtectedRoute>} />

        <Route path="/course-details/:id/item/video/:itemId" element={<ProtectedRoute><UserProvider><CourseVideo /></UserProvider></ProtectedRoute>} />
        <Route path="/course-details/:id/summary" element={<ProtectedRoute><UserProvider><CourseSummary /></UserProvider></ProtectedRoute>} />
        <Route path="/course-details/:id/signature" element={<ProtectedRoute><UserProvider><CourseSignature /></UserProvider></ProtectedRoute>} />

      
        <Route path="/facility-examiner-invites/:inviteId/edit"
          element={
            <ProtectedRoute>
              <UserProvider>
                <FacilityExaminerInvites />
              </UserProvider>
            </ProtectedRoute>
          }
        /> 
        <Route path="/ahca-regulations" element={<ProtectedRoute><UserProvider><AhcaRegulations /></UserProvider></ProtectedRoute>} />
        <Route path="/add-task-type" element={<ProtectedRoute><UserProvider><AddTaskType /></UserProvider></ProtectedRoute>} />
        <Route path="/task-type/:id" element={<ProtectedRoute><UserProvider><TaskTypeView /></UserProvider></ProtectedRoute>} />
        <Route path="/task-type/:id/edit" element={<ProtectedRoute><UserProvider><EditTaskType /></UserProvider></ProtectedRoute>} />
        <Route path="/tutorial" element={<ProtectedRoute><UserProvider><TutorialVideo /></UserProvider></ProtectedRoute>} />

        <Route path="/tutorial-video/:title" element={<ProtectedRoute><UserProvider><TutorialVideos /></UserProvider></ProtectedRoute>} />
        <Route path="/error" element={<ProtectedRoute><UserProvider><CourseError /></UserProvider></ProtectedRoute>} />
        <Route path="/facility-users/:Id/edit"
          element={
            <ProtectedRoute>
              <UserProvider>
                <EditManagerAccess />
              </UserProvider>
            </ProtectedRoute>
          }
        /> 
        <Route path="/user-invite-accept/:id/*"
          element={
                <UserInviteAcceptForm />
          }
        />

        <Route
          path="/password/reset/confirm/:uid/:token"
          element={<PasswordResetConfirm />}
        />

        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
      </ErrorBoundary>
      </Suspense>
    </Router>
    </Sentry.ErrorBoundary>
  );
};

export default App;
